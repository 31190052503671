import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Typography, Stack } from "@mui/material";
import typography from "assets/theme/base/typography";
import { get_order_status_color } from "utilities";
import { fDateTime } from "utilities";
import { formatCurrency } from "utilities";
import { LoadingButton } from "@mui/lab";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const OrderCardComponent = ({
  data,
  reCreateOrderHandler,
  handleCancelOrder,
  handleRefillOrder,
}) => {
  const { size } = typography;

  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState({ url: data?.url });
  const { refill, cancel } = JSON.parse(data.service.extra);

  const changeHandler = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const reCreateOrderHandler1 = async (e) => {
    e.preventDefault();
    try {
      const response = await reCreateOrderHandler({ url: details.url, id: data?.system_id });
      handleClose();
    } catch (e) {
      console.log("ERRRR", e);
      handleClose();
    }
  };
  const handleClickOpen = () => {
    // console.log("DATA FROM", data);
    setDetails({ url: data?.url });
    setOpen(true);
  };

  const handleClose = () => {
    setDetails({ url: data?.url });
    setOpen(false);
  };

  // React.useEffect(() => {
  //   // setDetails({ url: data?.url });
  // }, [details]);

  return (
    <div>
      <Accordion sx={{ pl: 0, backgroundColor: "#f0f2f5" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ px: 1 }}
        >
          <Stack direction="row" spacing={1}>
            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>ID</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.system_id}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>AMOUNT</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.amount}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>SERVICE</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.service.system_reference}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>STATUS</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.status ? get_order_status_color(data.status) : "failed🔴🔴"}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>QUANTITY</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.quantity}
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={2}>
            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>ZID</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.order_id === data.system_id ? "null" : data.order_id}
              </Typography>
            </Stack>

            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>USER</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.user.phone_number}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>TIME</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {fDateTime(data.updatedAt)}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ mt: 3 }} spacing={2} direction="row">
            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>START COUNT</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.start_count}
              </Typography>
            </Stack>

            <Stack direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>REMAIN</b>
              </Typography>
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                {data.remains}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Stack sx={{ mt: 3 }} direction="column">
              <Typography sx={{ fontSize: size.xs }} variant="caption">
                <b>LINK</b>
              </Typography>
              <Typography component={"a"} sx={{ fontSize: size.xs }} variant="caption">
                <a href={data.url}>{data.url}</a>
              </Typography>
            </Stack>
          </Stack>

          {/* cancel section  */}
          {data.status === "In progress" ? (
            <Stack>
              <Stack>
                {cancel ? (
                  <Stack sx={{ mt: 2 }}>
                    <LoadingButton
                      sx={{ backgroundColor: "rgba(76, 175, 80, 0.2)" }}
                      onClick={() => handleCancelOrder({ id: data.id })}
                    >
                      CANCEL
                    </LoadingButton>
                  </Stack>
                ) : null}
              </Stack>
            </Stack>
          ) : null}

          {/*  cancel section  */}

          {/* start refill */}
          {data.status === "Complete" ? (
            <Stack>
              {refill ? (
                <Stack sx={{ mt: 2 }}>
                  <LoadingButton
                    sx={{ backgroundColor: "rgba(76, 175, 80, 0.2)" }}
                    onClick={() => handleRefillOrder({ id: data.id })}
                  >
                    REFILL
                  </LoadingButton>
                </Stack>
              ) : null}
            </Stack>
          ) : null}
          {/* end refill */}

          {/* recreat order  */}
          {data.status === "Partial" ? (
            <Stack sx={{ mt: 2 }}>
              <LoadingButton
                sx={{ backgroundColor: "rgba(76, 175, 80, 0.2)" }}
                onClick={() => handleClickOpen(data)}
              >
                RE CREATE ORDER
              </LoadingButton>
            </Stack>
          ) : data.status === "Canceled" ? (
            <Stack>
              <LoadingButton
                style={{ backgroundColor: "rgba(76, 175, 80, 0.2)" }}
                onClick={() => handleClickOpen(data)}
              >
                RE CREATE ORDER
              </LoadingButton>
            </Stack>
          ) : !data.order_id ? (
            <Stack>
              <LoadingButton
                style={{ backgroundColor: "rgba(76, 175, 80, 0.2)" }}
                onClick={() => handleClickOpen(data)}
              >
                RE CREATE ORDER
              </LoadingButton>
            </Stack>
          ) : null}

          {/* end recreat order  */}
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: reCreateOrderHandler1,
        }}
      >
        <DialogTitle>RECREATE ORDERD</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Weka Order upya kwa order iliyo kua Partial au canceled bila malipo yoyote. Una uwezo
            wakubadirisha link kama mteja alikosea
          </DialogContentText>
          <TextField
            value={details.url}
            autoFocus
            onChange={changeHandler}
            required
            margin="dense"
            id="url"
            name="url"
            label="link"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            sx={{ backgroundColor: "rgba(76, 175, 80, 0.2)" }}
            variant="contained"
            type="submit"
          >
            CREATE ORDER
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderCardComponent;
